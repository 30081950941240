/*html {*/
/*    box-sizing: border-box;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*    font-size: 62.5%; !* 62.5% of 16px (browser default) = 10px *!*/
/*    color: #3c3c3c;*/
/*}*/

/**,*/
/**::before,*/
/**::after {*/
/*    box-sizing: inherit;*/
/*}*/

body {
    font-family: "Roboto", sans-serif;
    /*padding: 0;*/
    margin: 0;
    /*font-size: 1.6rem; !* set default size to 16px *!*/
    /*line-height: 1.8rem;*/
    /*background-color: #fafafa;*/
}

/*p {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*a {*/
/*    color: #2d8d9b;*/
/*    text-decoration: none;*/
/*}*/

/*h1,*/
/*h2,*/
/*h3,*/
/*h4,*/
/*h5,*/
/*h6 {*/
/*    font-family: "PT Serif", serif;*/
/*}*/

/*select.form-control,*/
/*textarea.form-control,*/
/*input.form-control {*/
/*    font-size: 1rem;*/
/*}*/

/*input[type="file"] {*/
/*    width: 100%;*/
/*}*/

/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/

/*.navMenu {*/
/*    display: flex;*/
/*    !*width: 120px;*!*/
/*    justifyContent: space-between;*/
/*}*/

/*.navMenu a {*/
/*    padding-right: 10px;*/
/*}*/

/*.grid-image:hover .overlay {*/
/*    opacity: 1;*/
/*    z-index: 99999;*/
/*    width: 200%;*/
/*    height: 200%;*/
/*}*/

/*.grid-image {*/
/*    position: relative;*/
/*}*/

/*.overlay {*/
/*    position: absolute;*/
/*    transition: all 0.3s ease-in;*/
/*    opacity: 0;*/
/*    background-color: rgba(0,0,0,1);*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    z-index: 0;*/
/*    border-radius: 10px;*/
/*}*/

/*.overlay img {*/
/*    max-height: 100%;*/
/*    padding: 10px;*/
/*    object-fit: contain;*/
/*}*/

/*.overlayToolbar {*/
/*    height: 20px;*/
/*}*/

/*.overlayImage img {*/
/*        max-height: 100%;*/
/*    padding: 10px;*/
/*    object-fit: contain;*/
/*}*/
